
import Vue from "vue"
import { getCategoryIcon } from "~/services/public/Listing"
import { getCategoryByV4Id } from "~/utils/categories"

interface ComponentData {
  multipleShow: boolean
  listings: Record<string, any>[]
  showActivePrelistings: boolean
  activePrelistingLimit: number
  activePrelistingCategory: number
}

export default Vue.extend({
  name: "ActivePrelisting",

  data(): ComponentData {
    return {
      multipleShow: false,
      listings: [],
      showActivePrelistings: true,
      activePrelistingLimit: 2,
      activePrelistingCategory: 0
    }
  },

  async mounted() {
    if (!this.$cookies.get("active_prelistings_banner")) {
      try {
        const url =
          this.$config.v4Host + "/ng/getactiveprelistings?load_count=" + this.activePrelistingLimit
        const result = await this.$axios.$get(url, {
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          },
          withCredentials: true
        })
        let listings = result.listings
        if (this.activePrelistingCategory) {
          listings = listings.filter((listing: Record<string, any>) => {
            return listing.category_id == this.activePrelistingCategory
          })
        }
        this.listings = listings
      } catch (e) {}
    }
  },

  methods: {
    getCategoryByV4Id,

    bannerClose(): void {
      const resDate = new Date()
      resDate.setDate(resDate.getDate() + 14)
      this.$cookies.set("active_prelistings_banner", true, {
        expires: resDate,
        path: "/",
        secure: true
      })

      this.showActivePrelistings = false
    },

    getTitle(listing: Record<string, any>): string {
      const category = this.getCategoryByV4Id(listing.category_id)
      let categoryName = category?.name
      if (listing.start_property_type === "office" || listing.end_property_type === "office") {
        categoryName = "Office Removals"
      }
      let title = category
        ? this.$t("category." + categoryName) + " " + this.$t("quote_banner.delivery_lower")
        : this.$t("quote_banner.delivery_upper")

      if (listing.pickup && listing.pickup.town) {
        title += this.$t("quote_banner.from") + listing.pickup.town
      }
      if (listing.delivery && listing.delivery.town) {
        title += this.$t("quote_banner.to") + listing.delivery.town
      }

      return title as string
    },

    getIcon(listing: Record<string, any>): string[] {
      const category = this.getCategoryByV4Id(listing.category_id)
      return getCategoryIcon(category?.icon)
    },

    getItems(listing: Record<string, any>): string {
      if (listing.items.length > 0) {
        let items = ""
        listing.items.every((itemValue, itemIndex) => {
          if (items.length > 50) {
            items += "..."
            return false
          }
          if (itemIndex != 0) {
            items += ", "
          }
          items += itemValue.qty + "x" + " " + itemValue.name

          return true
        })

        return items
      }

      return ""
    }
  }
})
